<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <h4 class="font-weight-bolder text-dark m-0 p-0">Shopping cart</h4>
        <p class="font-weight-bold text-colorGray">
          You have {{ getCart.length }} item in your cart 
        </p>
      </div>
    </b-card-header>
    <hr />
    <b-card-body>
      <b-table
        stacked="md"
        :fields="fieldsItems"
        :items="getCart"
        thead-class="tableHeadColor"
        small
        rounded
      >
        <template #head()="data">
          <div class="">
            <span class="font-weight-bolder text-colorBlack">{{
              data.label
            }}</span>
          </div>
        </template>
        <template #cell(Service)="row">
          <div class="py-2">
            <span class="text-dark font-weight-bolder">{{
              row.item.name
            }}</span>
            <br />
            <span
              v-if="row.item.type === 3"
              class="text-dark font-weight-bolder"
              >{{ row.item.roomName }}</span
            >
            <span
              v-if="row.item.type === 1"
              class="text-dark font-weight-bolder"
              >{{ row.item.departmentName }}</span
            >
            <span
              v-if="row.item.type === 5"
              class="text-dark font-weight-bolder"
            >
              <p v-html="row.item.tripName"></p>
            </span>
            <span
              v-if="row.item.type === 7"
              class="text-dark font-weight-bolder"
            >
              <p v-html="row.item.description"></p>
            </span>
          </div>
        </template>

        <template #cell(Type)="row">
          <div class="py-2">
            <span class="text-dark font-weight-bolder">{{
              getCartTypeName(row.item.type)
            }}</span>
          </div>
        </template>

        <template #cell(Quantity)="row">
          <div class="py-2">
            <span class="text-dark font-weight-bolder">{{
              row.item.quantity
            }}</span>
          </div>
        </template>
        <template #cell(numberOfDays)="row">
          <div class="py-2" v-if="row.item.type !== 1 && row.item.type !== 5">
            <b-button
              pill
              size="sm"
              variant="colorGreen"
              class="font-weight-bolder"
              @click="decreaseDaysAction(row.item)"
              >-</b-button
            >
            <span class="text-dark font-weight-bolder px-1">{{
              row.item.numberOfDays
            }}</span>
            <b-button
              pill
              size="sm"
              class="font-weight-bolder"
              variant="colorGreen"
              @click="increaseDaysAction(row.item)"
              >+</b-button
            >
          </div>
          <div class="py-2 text-left" v-else>
            <span class="text-dark font-weight-bolder">{{
              row.item.numberOfDays
            }}</span>
          </div>
        </template>

        <template #cell(Price)="row">
          <div class="py-2" v-if="row.item.type !== 1">
            <span class="text-dark font-weight-bolder"
              >{{ calculatePrice(row.item) }}$</span
            >
          </div>
          <div class="py-2" v-else>
            <span class="text-dark font-weight-bolder"
              >{{ row.item.price }}$</span
            >
          </div>
        </template>

        <template #cell(manage)="row">
          <div class="">
            <b-button
              pill
              size="sm"
              variant="danger"
              @click="removeItem(row.item)"
            >
              Remove Item
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="text-right mt-2">
        <b-button
          pill
          size="md"
          variant="info"
          style="margin-right:5px;"
          :disabled="getCart.length === 0"
          @click="checkout"
          v-show="getLoggedInUser"
        >
          Checkout
        </b-button>
        <b-button
          pill
          size="md"
          variant="info"
          @click="login"
          v-show="!getLoggedInUser"
        >
          Login
        </b-button>
        <b-button
          pill
          size="md"
          variant="primary"

          @click="addMore"
        >
          Add More
        </b-button>
      </div>
      <div class="text-left pl-3">
        <h3 class="font-weight-bolder text-dark">
          Total Payment: <span>{{ this.totalAmount }}$</span>
        </h3>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [util],
  data() {
    return {
      totalAmount: 0,
      fieldsItems: [
        { key: "Service", label: "Detail of Service" },
        { key: "Type", label: "Service" },
        { key: "quantity", label: "Quantity" },
        { key: "numberOfDays", label: "number Of Days" },
        { key: "Price", label: "Price" },
        { key: "manage", label: "" },
      ],
    };
  },
  mounted() {
    this.calculateTotalAmount();
  },
  methods: {
    ...mapActions({
      removeFromCart: "appData/removeFromCart",
      increaseDays: "appData/increaseDays",
      decreaseDays: "appData/decreaseDays",
    }),
    calculateTotalAmount() {
      const cart = this.getCart;

      let totalAmount = 0;

      cart.forEach((item) => {
        if (item.type === 1) {
          // For type 1, just add the price directly
          totalAmount += item.price;
        } else {
          // For other types, add the calculated price
          totalAmount += this.calculatePrice(item);
        }
      });

      this.totalAmount = totalAmount;
      this.$emit("update-total-amount", this.totalAmount);
    },
    calculatePrice(item) {
      return item.price * item.numberOfDays;
    },
    removeItem(item) {
      this.removeFromCart(item);
    },
    // Method to increase number of days for an item
    increaseDaysAction(item) {
      this.increaseDays(item);
    },

    // Method to decrease number of days for an item
    decreaseDaysAction(item) {
      this.decreaseDays(item);
    },
    calculatePrice(item) {
      return item.price * item.numberOfDays;
    },
    checkout() {
      this.$emit("next-step");
      console.log(this.getCart, "asdasd");
    },
    addMore() {
      this.$router.push({ name: "Home" });
    },
    login() {
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    ...mapGetters({
      getCart: "appData/getCart",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },
  watch: {
    getCart: {
      handler: "calculateTotalAmount",
      deep: true,
    },
  },
};
</script>
