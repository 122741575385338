<template>
  <div class="my-bg">
    <b-container>
      <b-row class="d-flex align-items-center justify-content-center">
        <b-col md="12" sm="12" class="py-5 py-xs-0">
          <div class="py-3">
            <div class="">
              <h1 class="font-weight-bolder text-colorBlue">Cart</h1>
            </div>
            <div class="pt-2">
              <h4 class="font-weight-normal text-colorGreen">
                Most Extensive & Economical Medical Procedures
              </h4>
            </div>
            <div class="pt-3 button-container d-lg-flex d-block">
              <div>
                <b-button
                  pill
                  variant="colorGreen"
                  size="md"
                  class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
                  @click="aboutPage"
                >
                  <img
                    src="@/assets/images/icons/medical-cross-fill.svg"
                    class="logo mr-50"
                  />
                  Learn More About Us
                </b-button>
              </div>
              <div>
                <b-button
                  pill
                  variant="colorGreen"
                  size="md"
                  class="font-weight-bolder"
                  @click="contactPage"
                >
                  <img
                    src="@/assets/images/icons/medical-cross-stroke.svg"
                    class="mr-50"
                  />Book Free Appointment
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  methods: {
    contactPage() {
      this.$router.push({ name: "ContactUs" });
    },
    aboutPage() {
      this.$router.push({ name: "AboutUs" });
    },
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/cart/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.button-container .first-button:hover .logo {
  content: url("~@/assets/images/icons/medical-cross-stroke.svg");
}
</style>
