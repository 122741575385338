<template>
  <b-card no-body>
    <b-card-header class="flex-column align-items-start">
      <b-card-title>Order Summary</b-card-title>
      <b-card-text class="text-muted mt-25">
        Please check the summary of your order
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-table
        stacked="md"
        :fields="fieldsItems"
        :items="getCart"
        thead-class="tableHeadColor"
        small
        rounded
      >
        <template #head()="data">
          <div class="">
            <span class="font-weight-bolder text-colorBlack">{{
              data.label
            }}</span>
          </div>
        </template>
        <template #cell(Service)="row">
          <div class="py-2">
            <span class="text-dark font-weight-bolder">{{
              row.item.name
            }}</span>
            <br />
            <span
              v-if="row.item.type === 3"
              class="text-dark font-weight-bolder"
              >{{ row.item.roomName }}</span
            >
          </div>
        </template>

        <template #cell(Type)="row">
          <div class="py-2">
            <span class="text-dark font-weight-bolder">{{
              getCartTypeName(row.item.type)
            }}</span>
          </div>
        </template>

        <template #cell(Quantity)="row">
          <div class="py-2">
            <span class="text-dark font-weight-bolder">{{
              row.item.quantity
            }}</span>
          </div>
        </template>
        <template #cell(numberOfDays)="row">
          <div class="py-2">
            <span class="text-dark font-weight-bolder px-1">{{
              row.item.numberOfDays
            }}</span>
          </div>
        </template>
        <template #cell(Price)="row">
          <div class="py-2" v-if="row.item.type !== 1">
            <span class="text-dark font-weight-bolder"
              >{{ calculatePrice(row.item) }}$</span
            >
          </div>
          <div class="py-2" v-else>
            <span class="text-dark font-weight-bolder"
              >{{ row.item.price }}$</span
            >
          </div>
        </template>
      </b-table>
    </b-card-body>
    <div class="text-left pl-3">
      <h3 class="font-weight-bolder text-dark">
        Total Payment: <span>{{ this.totalAmount }}$</span>
      </h3>
    </div>

    <div class="text-center">
      <b-button
        type="submit"
        variant="primary"
        pill
        class="px-5 mb-2"
        @click="placeOrder"
      >
        Place Order
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [util],
  data() {
    return {
      fieldsItems: [
        { key: "Service", label: "Service" },
        { key: "Type", label: "Type" },
        { key: "Quantity", label: "Quantity" },
        { key: "numberOfDays", label: "number Of Days" },
        { key: "Price", label: "Price" },
        { key: "manage", label: "" },
      ],

      rental: [],
      treatment: [],
      interpreter: [],
      hotel: [],
      safeTravel: [],
      tourism: [],
      insurance: [],
      visaAssistance: [],
      cart: [],
      cartWatchers: [],
    };
  },
  props: {
    totalAmount: {
      type: Number,
      required: true,
    },
  },
  mounted() {},
  created() {
    this.cart = this.getCart;
    this.cartWatchers = this.getCart;
    this.categorizeItems();
  },
  methods: {
    ...mapActions({
      createOrder: "appData/createOrder",
      initiatePayment: "appData/initiatePayment",
      payment: "appData/payment",
      clearCart: "appData/clearCart",
    }),

    calculatePrice(item) {
      return item.price * item.numberOfDays;
    },
    categorizeItems() {
      this.cart.forEach((item) => {
        switch (item.type) {
          case 0:
            this.rental.push({ id: item.itemId });

            break;
          case 1:
            this.treatment.push({ id: item.itemId });

            break;
          case 2:
            this.interpreter.push({ id: item.itemId });

            break;
          case 3:
            this.hotel.push({ id: item.itemId });

            break;
          case 4:
            this.safeTravel.push({ id: item.itemId });

            break;
          case 5:
            this.tourism.push({ id: item.itemId });

            break;
          case 6:
            this.insurance.push({ id: item.itemId });

            break;
          case 7:
            this.visaAssistance.push({ id: item.itemId });

            break;
        }
      });
    },
    async placeOrder() {
      try {
        const orderItems = [];

        if (Array.isArray(this.treatment)) {
          this.treatment.forEach((item) => {
            orderItems.push({ procedure: item.id });
          });
        }

        if (Array.isArray(this.tourist_package)) {
          this.tourist_package.forEach((item) => {
            orderItems.push({ tourist_trip: item.id });
          });
        }

        if (Array.isArray(this.room)) {
          this.room.forEach((item) => {
            orderItems.push({ room: item.id });
          });
        }

        if (Array.isArray(this.rental)) {
          this.rental.forEach((item) => {
            orderItems.push({ rental_car: item.id });
          });
        }

        if (Array.isArray(this.interprator)) {
          this.interprator.forEach((item) => {
            orderItems.push({ interpreter_service: item.id });
          });
        }

        if (Array.isArray(this.security)) {
          this.security.forEach((item) => {
            orderItems.push({ security_package: item.id });
          });
        }

        const orderData = {
          patient: this.getLoggedInUser.patient_data.id,
          order_items: orderItems,
        };

        const res = await this.createOrder(orderData);
        if (res.status === 200) {
          this.clearCart();
          const data = res.data["orderData"];
          console.log(data);
          // this.$swal({
          //   title: "Order created successfully",
          //   icon: "success",
          // });
          this.clearCart();
          await this.Payment(data);
          // this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
        console.log(error);
      }
      // this.$store.state.appData.cart = [];
      // this.totalAmount = 0;
      // this.$router.push({ name: "Home" });
    },
    async Payment(orderData) {
      try {
        this.showLoader();
        const response = await this.initiatePayment({
          order_id: orderData.id,
          amount: this.totalAmount,
          customer_name: orderData.patient_data.full_name,
          order_number: orderData.order_number,
        });
        if (
          response.data.Transaction &&
          response.data.Transaction.PaymentPage
        ) {
          const res_data = response.data;
          const res = await this.payment({
            order: orderData.id,
            amount: orderData.amount,
            transaction_details: JSON.stringify(res_data),
            transaction_id: res_data.Transaction.TransactionID,
          });
          console.log("backend response", res);
          if (res.status === 200) {
            this.clearCart();
            window.location.href = response.data.Transaction.PaymentPage;
          }
        } else {
          console.error("Payment initiation failed:", response.data);
        }
      } catch (error) {
        console.error("Error initiating payment:", error);
      } finally {
        this.hideLoader();
      }
    },
    showLoader() {
      document.getElementById("loading-bg").style.display = "block";
    },

    hideLoader() {
      document.getElementById("loading-bg").style.display = "none";
    },
  },
  computed: {
    ...mapGetters({
      getCart: "appData/getCart",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },
};
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
