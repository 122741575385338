<template>
  <div>
    <Topbar />
    <Navbar />
    <Banner />
    <b-container class="mb-3">
      <form-wizard
        ref="refFormWizard"
        color="#06bd46"
        :title="null"
        :subtitle="null"
        finish-button-text="Submit"
        back-button-text="Previous"
        hide-buttons
        class="checkout-form-wizard steps-transparent mt-3"
      >
        <!-- account detail tab -->
        <tab-content title="Checkout" icon="feather icon-shopping-cart">
          <cart-checkout
            @next-step="formWizardNextStep"
            @update-total-amount="handleUpdateTotalAmount"
          />
        </tab-content>

        <!-- social link -->
        <tab-content title="Order" icon="feather icon-credit-card">
          <cart-order
            :payment-details="checkoutDetails.payment"
            :total-amount="totalAmount"
            @next-step="formWizardNextStep"
          />
        </tab-content>
      </form-wizard>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import CartOrder from "./CartOrder.vue";
import CartCheckout from "./CartCheckout.vue";
import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/home/Navbar.vue";
import Banner from "@/components/cart/Banner.vue";
import Footer from "@/components/home/Footer.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    CartCheckout,
    CartOrder,
    Topbar,
    Navbar,
    Banner,
    Footer,
  },
  data() {
    return {
      checkoutDetails: {
        payment: {},
      },
      totalAmount: 0,
    };
  },
  methods: {
    formWizardNextStep() {
      this.$refs.refFormWizard.nextTab();
    },
    handleUpdateTotalAmount(amount) {
      this.totalAmount = amount;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
