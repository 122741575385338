var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white"},[_c('b-container',[_c('b-navbar',{staticClass:"p-0",attrs:{"toggleable":"lg","type":"light"}},[_c('b-navbar-brand',{attrs:{"href":"#"}},[_c('div',[_c('b-link',{staticClass:"font-weight-bolder text-colorText",attrs:{"to":{ name: 'Home' }}},[_c('img',{staticClass:"mr-1 logo1",attrs:{"src":require("@/assets/images/logo/medask.png")}})])],1)]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto py-2"},[_c('b-nav-item',{staticClass:"px-50"},[_c('b-link',{staticClass:"font-weight-bolder text-colorText",attrs:{"to":{ name: 'Home' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Home")])])],1),_c('b-nav-item-dropdown',{staticClass:"px-50",attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"font-weight-bolder text-colorText my-span"},[_vm._v("Treatments")])]},proxy:true}])},[_c('div',{staticClass:"dropdown-menu-columns",style:({ width: _vm.dynamicColumnWidth })},_vm._l((_vm.numberOfColumns),function(column,index){return _c('div',{key:index,staticClass:"column",attrs:{"data-column-index":index}},_vm._l((_vm.getColumnDepartments(index)),function(department,departmentIndex){return _c('div',{key:departmentIndex},[_c('b-dropdown-item',{attrs:{"to":{
                      name: 'Treatment',
                      params: {
                        treatmentName: department.name,
                        id: department.id,
                      },
                    }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v(_vm._s(department.name))])])],1)}),0)}),0)]),_c('b-nav-item-dropdown',{staticClass:"px-50",attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"font-weight-bolder text-colorText my-span"},[_vm._v("Hospitals ")])]},proxy:true}])},_vm._l((_vm.hospitals),function(hospital){return _c('b-dropdown-item',{key:hospital.id,attrs:{"to":{
                name: 'Hospital',
                params: {
                  hospitalName: hospital.name,
                  id: hospital.id,
                },
              }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v(_vm._s(hospital.name))])])}),1),_c('b-nav-item',{staticClass:"px-50"},[_c('b-link',{staticClass:"font-weight-bolder text-colorText",attrs:{"to":{ name: 'AllDoctor' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Doctors")])])],1),_c('b-nav-item-dropdown',{staticClass:"px-50",attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"font-weight-bolder text-colorText my-span"},[_vm._v("Services")])]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":{ name: 'VisaAssistance' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Visa Assistance")])]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Hotel' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Hotels")])]),_c('b-dropdown-item',{attrs:{"to":{ name: 'CarRental' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Car Rental")])]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Interpreter' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Interpreter")])]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Tourism' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Tourism")])]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Insurance' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Insurance")])])],1),_c('b-nav-item',{staticClass:"px-50"},[_c('b-link',{staticClass:"font-weight-bolder text-colorText my-span my-nav-link",attrs:{"to":{ name: 'AboutUs' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("About Us")])])],1),_c('b-nav-item',{staticClass:"px-50"},[_c('b-link',{staticClass:"font-weight-bolder text-colorText my-span my-nav-link",attrs:{"to":{ name: 'ContactUs' }}},[_c('span',{staticClass:"my-span my-nav-link"},[_vm._v("Contact Us")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }